import { Helmet } from 'react-helmet';

import GraphiQL from './components/GraphiQL';
import { getEnv } from './helpers/env';

import './App.css';
import 'graphiql/graphiql.css';

/**
 * Render App
 *
 * @returns JSX.Element
 */
function App() {
    const headerTitle = getEnv('REACT_APP_TITLE', true);

    return (
        <div className="App">
            <Helmet>
                <title>{ headerTitle ? `${headerTitle} - ` : '' }Playground</title>
            </Helmet>
            <GraphiQL />
        </div>
    );
}

export default App;
