export type EnvVariables = {
    // GraphiQL related .env variables
    REACT_APP_GRAPHIQL_ENDPOINT_URL: string
    REACT_APP_GRAPHIQL_DEFAULT_HEADERS: string,

    // App related .env variables
    REACT_APP_TITLE: string
};

export function getEnv<T extends keyof EnvVariables>(key: T, isOptional: true): Partial<EnvVariables>[T];
export function getEnv<T extends keyof EnvVariables>(key: T, isOptional?: false): EnvVariables[T];

/**
 * Get environment variable value by key from .env
 *
 * @param key
 * @param isOptional
 * @returns Partial<EnvVariables>[T] | EnvVariables[T]
 */
export function getEnv<T extends keyof EnvVariables>(key: T, isOptional = false) {
    const envVariableValue = process.env[key];

    if (!isOptional && envVariableValue === undefined) {
        throw new Error(`${key} not found in .env config!`);
    }

    return envVariableValue;
}
