/**
 *  Get url search param
 *
 * @param name
 * @returns string | null
 */
export const getUrlSearchParam = (name: string): string | null => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    return params.get(name);
};

/**
 *  Get url search params
 *
 * @param names
 * @returns Record<string, string | null>
 */
export const getUrlSearchParams = (names: string[]): Record<string, string | null> => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    return names.reduce((searchParams: Record<string, string | null>, name) => ({
        ...searchParams,
        [name]: params.get(name)
    }), {});
};
