import { createGraphiQLFetcher } from '@graphiql/toolkit';
import GraphiQL from 'graphiql';
import { Component } from 'react';

import { getEnv } from '../helpers/env';
import { getUrlSearchParam, getUrlSearchParams } from '../helpers/url';

/**
 * Backups storage on graphiql change
 *
 * @returns JSX.Element
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unreachable code error
GraphiQL.Logo = function () {
    return (
        <div className="graphiql-logo-wrapper">
            <img
                alt="logo"
                src="favicon.ico"
                className="logo"
            />
            <span className="title">
              Playground
            </span>
        </div>
    );
};

class GraphiQLComponent extends Component {
    /**
     * Backups storage on graphiql change
     *
     * @param type
     * @param value
     * @returns void
     */
    backupStorageOnChange(type: 'query' | 'headers' | 'variables', value?: string): void {
        const urlParam = getUrlSearchParam(type);

        if (!urlParam) {
            localStorage.setItem(`graphiql:${type}:backed`, value || '');
        }
    }

    /**
     * Get graphiql params from url or backed values
     *
     * @returns graphiQLParams
     */
    getGraphiQLParams(): { query: string, headers: string | null, variables: string | null } {
        const {
            query: queryUrlParam,
            headers: headersUrlParam,
            variables: variablesUrlParam
        } = getUrlSearchParams(['query', 'headers', 'variables']);

        return {
            query: queryUrlParam || localStorage.getItem('graphiql:query:backed') || '',
            headers: headersUrlParam || localStorage.getItem('graphiql:headers:backed'),
            variables: variablesUrlParam || localStorage.getItem('graphiql:variables:backed')
        };
    }

    /**
     * Convert values to json string with alignment
     *
     * @param value
     * @param additionalValue
     * @returns string
     */
    convertWithAlignment(value: string | null, additionalValue?: Record<string, unknown>): string {
        return JSON.stringify(
            {
                ...additionalValue,
                ...value && JSON.parse(value)
            },
            null,
            2
        );
    }

    /**
     * Get headers
     *
     * @param headers
     * @returns string
     */
    getHeaders(headers: string | null): string {
        const defaultHeaders = getEnv('REACT_APP_GRAPHIQL_DEFAULT_HEADERS', true) || '';
        const formattedDefaultHeaders = defaultHeaders.split(',').reduce(
            (acc: Record<string, string>, header) => ({ ...acc, [header]: '...' }),
            {}
        );

        return this.convertWithAlignment(headers, formattedDefaultHeaders);
    }

    /**
     * Render GraphiQL playground
     *
     * @returns JSX.Element
     */
    render(): JSX.Element {
        const { query, headers, variables } = this.getGraphiQLParams();

        return (
            <GraphiQL
                fetcher={createGraphiQLFetcher({ url: getEnv('REACT_APP_GRAPHIQL_ENDPOINT_URL') })}
                shouldPersistHeaders
                query={query}
                variables={this.convertWithAlignment(variables)}
                headers={this.getHeaders(headers)}
                onEditQuery={(value) => this.backupStorageOnChange('query', value)}
                onEditHeaders={(value) => this.backupStorageOnChange('headers', value)}
                onEditVariables={(value) => this.backupStorageOnChange('variables', value)}
            />
        );
    }
}

export default GraphiQLComponent;
